import React from 'react';
import './style.css';
import {Link} from 'react-router-dom';
import Cal from '../../components/buttons/cal';
import Connect from '../../components/connect/connect';
import {SupportAgent, Speed, PrecisionManufacturing, Savings, FollowTheSigns} from '@mui/icons-material';


const Home = () => {

    return(
        <>
           <div className="main-home">
                <section className="hero-main">
                    <h3 className="hero-head"><span className="colr">Transforming Ideas</span> Into <span className="colr">Digital Success</span> <br/> For Everyday Businesses</h3>
                    <p className="hero-desc">
                       We Empower small and medium businesses with affordable, scalable, and innovative tech solutions that drive real results.
                    </p>
                    {/* <Link to="/services" className=""> <JoinBTN text="Free Appointment" />
                    </Link> */}
                    <Cal text="Free Appointment" name="joinerbtn"/>
                    <Link to="/services" className="hero-btn">Our Services</Link>
                </section>
                <section className="service-main">
                    <div className="serviceb">
                        <h2 className="serv-head"><span className="rline">Services</span></h2>
                        <Link to="/services" className="services-btn" >View All Services</Link>
                    </div>
                    <Link to="/services" className="serv-btn" />
                    <div className="serv-div">
                        <Link to="software-development" className="serv">
                            <div className="">
                                <img src="https://res.cloudinary.com/dohtech/image/upload/v1707545544/dohtech/softdev_hvz0hi.png" alt="software development" className="serv-img" />
                                <h3 className="serv-name">Software Development</h3>
                            </div>
                        </Link>
                        <Link to="/it-cloud-consulting" className="serv">
                            {/* <div className=""> */}
                                <img src="https://res.cloudinary.com/dohtech/image/upload/v1707545544/dohtech/server_klhax0.png" alt="software development" className="serv-img" />
                                <h3 className="serv-name">IT Support & Cloud Consulting</h3>
                            {/* </div> */}
                        </Link>
                        <Link to="/dohtech-training" className="serv">
                            <div className="">
                                <img src="https://res.cloudinary.com/dohtech/image/upload/v1707545544/dohtech/learning_k80edb.png" alt="software development" className="serv-img" />
                                <div>
                                    <h3 className="serv-name">Software Development / IT Training</h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                </section>
                <section className="soft-section">
                    <div className="soft e">
                        <h3 className="aphead">Web & App Development</h3>
                        <h2 className="app-header">We turn your ideas into <span className="rline">reality</span></h2>
                        <p className="apphead-desc">
                            Our team at DOHTECH SOLUTIONS specializes in transforming your unique ideas into powerful digital solutions. Whether it's developing innovative software, creating engaging content, or 
                            optimizing your online presence, we bring your vision to life with expertise and precision.
                        </p>
                        <Link to="/software-development" className="apphead-btn">Learn More</Link>
                        {/* <img src="https://res.cloudinary.com/dohtech/image/upload/v1707608267/dohtech/devo_sq65az.png" alt="app development" className="soft-img" /> */}
                    </div>
                    <div className="soft">
                        <div className="soft-divide">
                            <div className="vid">
                               <div className="vidblock">
                                    <img src="https://res.cloudinary.com/dohtech/image/upload/v1711763108/dohtech/ap1_oksrzn.png" alt="app" className="con-img" />
                                    <h3 className="vid-head">UI/UX Design</h3>
                                    <p className="vid-desc">
                                        Our UI/UX design creates intuitive, user-friendly interfaces that deliver a seamless and engaging experience, ensuring every interaction enhances your brand.
                                    </p>
                               </div>
                               <div className="vidblock">
                                    <img src="https://res.cloudinary.com/dohtech/image/upload/v1711763105/dohtech/ap3_kwkisu.png" alt="app" className="con-img" />
                                    <h3 className="vid-head">App Development</h3>
                                    <p className="vid-desc">
                                        Our UI/UX design creates intuitive, user-friendly interfaces that deliver a seamless and engaging experience, ensuring every interaction enhances your brand.
                                    </p>
                               </div>
                            </div>
                            <div className="vid r">
                                <div className="vidblock">
                                    <img src="https://res.cloudinary.com/dohtech/image/upload/v1711763104/dohtech/ap4_jxjv4j.png" alt="app" className="con-img" />
                                    <h3 className="vid-head">App & Web Maintenance</h3>
                                    <p className="vid-desc">
                                        Our UI/UX design creates intuitive, user-friendly interfaces that deliver a seamless and engaging experience, ensuring every interaction enhances your brand.
                                    </p>
                               </div>
                               <div className="vidblock">
                               <img src="https://res.cloudinary.com/dohtech/image/upload/v1711763104/dohtech/ap5_oaxxgm.png" alt="app" className="con-img" />
                                    <h3 className="vid-head">Upgrade & Optimization</h3>
                                    <p className="vid-desc">
                                        Our UI/UX design creates intuitive, user-friendly interfaces that deliver a seamless and engaging experience, ensuring every interaction enhances your brand.
                                    </p>
                               </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="md-section">
                    <h3 className="midhead"><span className="rlinee">Managed</span> IT Services</h3>
                    <div className="hostm">
                        <div className="hostmin l">
                            <h3 className="hostn-head">Server Management</h3>
                        </div>
                        <div className="hostmin m">
                            <h3 className="hostn-head">Cloud Support</h3>
                        </div>
                        <div className="hostmin r">
                            <h3 className="hostn-head">Startup IT Support</h3>
                        </div>
                    </div>
                    <Link to="/dohtech-msp" className="mid-btn">Learn More</Link>
                </section>
                <section className="mde-section">
                    <div className="mde g">
                      
                    </div>
                    <div className="mde t">
                        <h3 className="mded-head"><span className="rline">Reliable </span> & Trusted IT <br/> Solutions for Your Business </h3>
                        <div className="softerd">
                         
                        </div>
                        <p className="mde-desc e">
                        We provide reliable and trusted IT services tailored to meet your business needs. 
                        From robust infrastructure management to seamless tech support, our expert team ensures your operations run smoothly, 
                        allowing you to focus on what matters most—growing your business.
                        </p>
                        <div className="modesc">
                            <div className="mdesd">
                                <SupportAgent className="mde-ico" />
                                <h3 className="mde-head">24/7 IT Support & Monitoring</h3>
                                <p className="mde-desc">
                                    Faster issue detection and resolution
                                </p>
                            </div>
                            <div className="mdesd">
                                <Speed className="mde-ico" />
                                <h3 className="mde-head">Quicker Deployments</h3>
                                <p className="mde-desc">
                                    Faster project planning and execution
                                </p>
                            </div>
                            <div className="mdesd">
                                <PrecisionManufacturing className="mde-ico" />
                                <h3 className="mde-head">Automation & Efficiency</h3>
                                <p className="mde-desc">
                                    Custom technologies to reduce manual processes
                                </p>
                            </div>
                            <div className="mdesd">
                                <Savings className="mde-ico" />
                                <h3 className="mde-head">Reduced Total IT Expenditure</h3>
                                <p className="mde-desc">
                                    Stay on budget with tech that runs your business
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="soft-section">
                    <Connect />
                </section>
           </div>
        </>
    )
}

export default Home;